import React, { useState } from 'react'
import his1 from "../imgcomponents/wallpaper-history.jpg";
import "./history.css";
import v1 from "../imgcomponents/valor1.png";
import v2 from "../imgcomponents/valor2.png";
import v3 from "../imgcomponents/valor3.png";
import v4 from "../imgcomponents/valor4.png";

export const HISTORY = () => {


    const[viewv1, setviewv1]=useState(false);
    const[viewv2, setviewv2]=useState(false);
    const[viewv3, setviewv3]=useState(false);
    const[viewv4, setviewv4]=useState(false);
    const[viewdesc, setviewdesc]=useState(false);

    function handlev1(){
        if(viewv1===true){
           setviewdesc(false); 
           setviewv1(false);
        }else{
            setviewdesc(true);
            setviewv1(true);
            setviewv2(false);
            setviewv3(false);
            setviewv4(false);
        } 
    }
    function handlev2(){
        if(viewv2===true){
            setviewdesc(false);
            setviewv2(false);
        }else{
            setviewdesc(true);
            setviewv1(false);
            setviewv2(true);
            setviewv3(false);
            setviewv4(false);
        }
    }
    function handlev3(){
        if(viewv3===true){
            setviewdesc(false);
            setviewv3(false);
        }else{
            setviewdesc(true);
            setviewv1(false);
            setviewv2(false);
            setviewv3(true);
            setviewv4(false);
        }  
    }
    function handlev4(){
        if(viewv4===true){
            setviewdesc(false);
            setviewv4(false);
        }else{
            setviewdesc(true);
            setviewv1(false);
            setviewv2(false);
            setviewv3(false);
            setviewv4(true);
        }  
    }

  return (
    <div className='history-container' id="nosotros-black-section">
        <div className='history-seccions'>
            <div className="his1">
                <div className="img-history">
                    <img src={his1} alt=''></img>
                </div>

            </div>
            <div className="his2">
                <div className='title-history'>
                    <p>PERFIL DE MARCA</p>
                </div>
                <div className='desc-history'>
                    <p className='desc-company'>
                    Blackboard es una cerveza artesanal mexicana buscando 
                    abrirse camino en el mercado nacional con una propuesta
                    innovadora. 
                    </p>
                    
                    <p className='valores-title'>VALORES: </p>
                    
                
                <div className='contenedor-valores'>   
                        <div className='valor1'>
                            <img className={`corch-img ${viewv1===true?"seleccionado-val":""}`} src={v1} onClick={()=>handlev1()}></img>
                        </div>
                        <div className='valor2'>
                            <img className={`corch-img ${viewv2===true?"seleccionado-val":""}`} src={v2} onClick={()=>handlev2()}></img>
                        </div>
                        <div className='valor3'>
                            <img className={`corch-img ${viewv3===true?"seleccionado-val":""}`} src={v3} onClick={()=>handlev3()}></img>
                        </div>
                        <div className='valor4'>
                            <img className={`corch-img ${viewv4===true?"seleccionado-val":""}`} src={v4} onClick={()=>handlev4()}></img>
                        </div>                    
                    </div> 
                    {
                        viewdesc===true?
                        <div className="descval">
                        {
                            viewv1===true?
                            <p>
                            Blackboard es una cerveza artesanal que se distingue por su <b>"Autenticidad"</b>. Cada botella 
                            está cuidadosamente elaborada con pasión y dedicación, utilizando ingredientes seleccionados 
                            y un proceso de producción meticuloso. Nos enorgullece ofrecer una experiencia cervecera auténtica
                             y única, que refleja nuestra pasión por la creación de cervezas excepcionales.
                            </p>:
                            viewv2===true?
                            <p>
                            La <b>"Calidad"</b> es un valor fundamental en Blackboard. Nos comprometemos a ofrecer cervezas artesanales 
                            de la más alta calidad en cada botella que producimos. Desde la selección de los ingredientes hasta 
                            el proceso de elaboración y el embotellado, seguimos rigurosos estándares de calidad para garantizar 
                            que cada cerveza que sale de nuestras instalaciones sea excepcional en sabor, aroma y consistencia.
                            </p>:
                            viewv3===true?
                            <p>
                            En Blackboard, valoramos la <b>"Adaptabilidad" </b>y la capacidad de respuesta a las necesidades y preferencias 
                            cambiantes de nuestros clientes. Nos esforzamos por ofrecer una variedad de estilos y opciones de cerveza 
                            para satisfacer los gustos individuales. Estamos abiertos a escuchar a nuestros clientes y estamos dispuestos 
                            a adaptarnos para proporcionarles la mejor experiencia cervecera posible.
                            </p>:
                            viewv4===true?
                            <p>
                            En Blackboard, nos esforzamos por llevar la <b>"Innovación"</b> al mundo de la cerveza artesanal. 
                            Estamos constantemente explorando nuevas técnicas de elaboración y experimentando con
                            ingredientes innovadores para crear sabores sorprendentes y emocionantes. Nuestra búsqueda de 
                            la innovación nos impulsa a romper barreras y a desafiar los límites de lo que es posible en 
                            la cerveza artesanal.
                            </p>:<></>
                        }                       
                    </div>:<></>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
