import React, { useEffect, useState } from 'react';
import "./slider.css";
import fondo1 from "../imgcomponents/slider1.jpg";
import fondo2 from "../imgcomponents/slider2.jpg";
import fondo3 from "../imgcomponents/slider3.jpg";
import fondo4 from "../imgcomponents/slider4.jpg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export const SLIDER = () => {

  // Array de imágenes para el carrusel
  const images = [fondo1, fondo2, fondo3]; // Asegúrate de tener las imágenes importadas correctamente
  const [currentImage, setCurrentImage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [nextImage, setNextImage] = useState((currentImage + 1) % images.length);

  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 100); // Ajusta el intervalo según tus necesidades

    // Cuando el progreso llegue a 100, cambiar a la siguiente imagen
    if (progress >= 100) {
      clearInterval(timer);
      setProgress(0); // Reiniciar el progreso
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  useEffect(() => {
    // Preparar la siguiente imagen
    setNextImage((currentImage + 1) % images.length);
  }, [currentImage, images.length]);

  const previousImage = () => {
    setCurrentImage((prevImage) => {
      const newIndex = (prevImage - 1 + images.length) % images.length;
      return newIndex;
    });
  };

  const nextImagem = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };


  return (
    <div className='slider'>
        <div className='previmg'>
          <ArrowForwardIosIcon onClick={()=>previousImage()}></ArrowForwardIosIcon>
        </div>
          <div className='slider-carrusel'>
              <img src={images[currentImage]} alt='' />
                <div className='contenido-slider'> 
                </div>
          </div>
        <div className="neximg">
          <ArrowBackIosIcon onClick={()=>nextImagem()}></ArrowBackIosIcon>
        </div>
        <div className='barra-carga' style={{ width: `${progress}%` }}></div>
      </div>
  )
}



 {/* <p>Adultos jóvenes, Millenials en busca de productos
    destacables y generadores de tendencias, “instagrameables”
    y con propuesta única.
  </p> */}