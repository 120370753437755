import React, { useState } from 'react'
import { NAVBAR } from './Navbar'
import { SLIDER } from './Slider';
import "./home.css";
import logo4 from "../imgcomponents/logo4.jpg";
import { BODY_PAGE } from './body_page';
import { CONTACT } from './contac';
import { FOOTER } from './footer';
import { OPINION } from './opinion';
import { HISTORY } from './history';
import { PRODUCTOS } from './products';


export const HOME = () => {


  const [alert, setalert]=useState(false);
  const [edad, setedad] = useState(
    localStorage.getItem("edad") === null
      ? false
      : localStorage.getItem("edad") === "true"
      ? true
      : false
  );

  function handleedad(){
    localStorage.setItem("edad", true);
    setedad(true);
  }
  function alertas(){
    setalert(true);
  }

  return (
    <>
    {edad === true?
    <div>
      <NAVBAR></NAVBAR>
      <SLIDER></SLIDER>
      <BODY_PAGE></BODY_PAGE>
      <HISTORY></HISTORY>
      <PRODUCTOS></PRODUCTOS>
      <OPINION></OPINION>
      <CONTACT></CONTACT>
      <FOOTER></FOOTER>
    </div>:
    <div className='home-request'>
      <div className='request-year'>
        <div className='request-logo'>
          <img src={logo4} alt='logo'></img>
        </div>
         <p className='request-p'>
          <span className='span1'>¿Eres May</span>
          <span className='span2'>or de Edad?</span></p>
        <div className='button-seccion'>
          <button className='request-button1' onClick={()=>handleedad()}>SI</button>
          <button className='request-button2' onClick={()=>alertas()}>NO</button>
        </div>
        
        {
          alert===true?
          <div className='alert-edad'>
            <p className='p-alert'>Regresa cuando seas mayor</p> 
          </div>
          :<></>
        }
      
      </div>

    </div>  
    }
    </>
    
  )
}
