import logo from './logo.svg';
import './App.css';
import { HOME } from './pages/Home';

function App() {
  return (
    <>
      <HOME></HOME>
    </>  
  );
}

export default App;
