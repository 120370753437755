import React, { useState } from 'react'
import "./contact.css";
import axios from "axios";

export const CONTACT = () => {

const [usuario, setusuario]=useState("");
const [correo, setcorreo]=useState("");
const [mensaje, setmensaje]=useState("");

function enviar_mensaje(){

  if(usuario==null || usuario=="" || correo==null ||correo=="" || mensaje=="" || mensaje==null  ){
    alert("Todos los campos son requeridos"); 
    return null;
  }
    var data = {
        "name": usuario,
        "email": correo,
        "Message": mensaje 
    }
    var url = `https://nuk297ae3k.execute-api.us-east-1.amazonaws.com/prod/snsblackboard`;
    var axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    }
    
    axios
    .post(url, data,axiosConfig)
    .then((response) => {
      console.log(response);
      setusuario("");
      setcorreo("");
      setmensaje("");
      alert("Mensaje enviado");
     })
    .catch((err) => {
      console.log("error post", err);
      alert("Error al enviar el mensaje");
    });
}

  return (
    <div className='contact-container' id='contacto-container-seccion'>
        <div className='grid-contact'>
            <div className='map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d235.98986803918055!2d-97.07321831396509!3d18.84987457904524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1686256547661!5m2!1ses-419!2smx" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">    
                </iframe>
            </div>
            <div className='form'>
                <div className='form-items'>
                    <h1>CONTACTO</h1>
                    <div className='input1'>
                        <input 
                          placeholder='Nombre' 
                          value={usuario} 
                          onChange={(e)=>setusuario(e.target.value)}
                        ></input>
                        <input placeholder='Correo' value={correo} onChange={(e)=>setcorreo(e.target.value)}></input>
                        <textarea placeholder='Mensaje' value={mensaje} onChange={(e)=>setmensaje(e.target.value)}></textarea>
                    </div>
                    <button onClick={()=>enviar_mensaje()} className='send-email'>
                            Enviar Correo
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}
