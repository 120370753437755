import React from 'react'
import "./productos.css";


export const PRODUCTOS = () => {
  return (
    <div className='products_container'>
        <div className='meta'>
          <p className='title-meta'>PROPUESTA DE VALOR</p>
          <p className='text-meta'>Adultos jóvenes, Millenials en busca de productos destacables y generadores
          de tendencias, "instagrameables" y con una propuesta única.</p>
        </div>
    </div>
  )
}
