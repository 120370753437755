import React, { useState } from 'react';
import "./opinion.css";
import star from "../imgcomponents/estrellas.png";
import user1 from "../imgcomponents/user-opin.png";
import person1 from "../imgcomponents/persona1.PNG";
import person2 from "../imgcomponents/persona2.PNG";
import person3 from "../imgcomponents/persona3.PNG";

export const OPINION = () => {

  return (
    <div className='opinion-container'> 
      <div className='opinion-sections'>
        <div className='title-opinion'>
          <p>Nuestros Clientes Opinan</p>
        </div>
        <div className='op-conatiner'>
          <div className="op-item1">
            <img src={star} className="img-item" alt=''></img>
            <p className='p-item'>
              Un sabor singular ¡Lo recomiendo!
            </p>
            <div className='container-user-info'>
              <img src={person1} alt=''></img>
              <p className='nom-ape'>Anónimo</p>
              <p className='city-state'>Orizaba, Veracruz</p>
            </div>
            
          </div>
          <div className="op-item2">
          
          <img src={star} className="img-item" alt=''></img>
            <p className='p-item'>
            "Me encanta el carácter único de Blackboad, una cerveza artesanal de primera."
            </p>
            <div className='container-user-info'>
              <img src={person2} alt=''></img>
              <p className='nom-ape'>Anónimo</p>
              <p className='city-state'>Orizaba, Veracruz</p>
            </div>
          </div>
          <div className="op-item3">
          <img src={star} className="img-item" alt=''></img>
            <p className='p-item'>
             "Una cerveza artesanal de alto nivel."
            </p>
            <div className='container-user-info'>
              <img src={person3} alt=''></img>
              <p className='nom-ape'>Anónimo</p>
              <p className='city-state'>Orizaba, Veracruz</p>
            </div>
          </div>
        </div>
      </div> 
    </div>
  )
}
