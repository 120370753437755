import React from 'react'
import logo2 from "../imgcomponents/logo2.jpg";
import "./navbar.css";


export const NAVBAR = () => {
  return (
    <div className='navbar'>
        <div className='content-navbar'>
            <div className='logo-nav'>
              <img src={logo2} alt=''></img>
            </div>
            <div className='menu-nav'>
              <div className='menu-items'>
                <ul className='ul-items'>
                  <li className='li-items'><a href='/'>Inicio</a></li>
                  <li className='li-items'><a href='#nuestros_productos'>Productos</a></li>
                  <li className='li-items'><a href='#nosotros-black-section'>Nosotros</a></li>
                 
                  <li className='li-items'><a href='#contacto-container-seccion'>Contacto</a></li>  
                </ul> 
              </div>
            </div>
        </div>
    </div>
  )
}
