import React from 'react'
import "./footer.css";
import logo from "../imgcomponents/logo4.jpg";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';

export const FOOTER = () => {

  const phoneNumber = '2721350288'; // Reemplaza con tu número de WhatsApp
  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };




  return (
    <div className='footer-content'>
      <div className='sections-footer'>
          <div className='footer1'>
              <img src={logo} alt=''></img>
          </div>
          <div className='footer2'>
            <p className='contac'>
              Contacto
            </p>
            <p className='text-dir'>
              Independencia 900, Barrio Nuevo, Orizaba, Ver.
            </p>
            <p className='text-dir'>
              272 135 02 88
            </p>
            <p className='text-dir'>
              cervezablackboard@gmail.com
            </p>


          </div>
          <div className='footer3'>
          <p className="title-foot">Nuestras Redes</p>
          <div className="Instagram-red">
            <a href="https://www.instagram.com/cerveza.blackboard/?utm_medium=copy_link" target="_blank" rel="noopener noreferrer" className="redes-sociales">
              <InstagramIcon/><span className="redes-sociales-sp">BLACKBOARD</span>
            </a>
          </div>
          <div className="whatsapp-red">
            <a className="redes-sociales" 
              onClick={()=>handleWhatsAppClick()}
            >
              <WhatsAppIcon/><span className="redes-sociales-sp">BLACKBOARD</span>
            </a>
          </div>

          </div>
      </div>
    </div>
  )
}
