import React, { useState } from 'react';
import "./body_pages.css";
import photo1 from "../imgcomponents/fondo1.PNG";
import photo2 from "../imgcomponents/fondo-verde.PNG";
import carta1 from "../imgcomponents/carta1.jpg";
import carta2 from "../imgcomponents/carta2.jpg";
import carta3 from "../imgcomponents/carta3.jpg";
import carta4 from "../imgcomponents/carta4.jpg";
import carta5 from "../imgcomponents/carta5.jpg";
import logo from "../imgcomponents/logo1.jpg";

export const BODY_PAGE = () => {

  const[viewp1, setviewp1]=useState(false);
  const[viewp2, setviewp2]=useState(false);

  function handleviewp1(){
    if(viewp1===false){
      if(viewp2===true){
        setviewp1(true);
        setviewp2(false);
        document.getElementById('des-prod').scrollIntoView({ behavior: 'smooth' });
      }else{
        setviewp1(true);
        document.getElementById('des-prod').scrollIntoView({ behavior: 'smooth' });
      }
    }else{
      setviewp1(false);
      document.getElementById('perfil-marc').scrollIntoView({ behavior: 'smooth' });
    }
  }
  function handleviewp2(){
   if(viewp2===false){
    if(viewp1===true){
      setviewp2(true);
      setviewp1(false);
      document.getElementById('des-prod').scrollIntoView({ behavior: 'smooth' });
    }else{
      setviewp2(true);
      document.getElementById('des-prod').scrollIntoView({ behavior: 'smooth' });
    }
   }else{
    setviewp2(false);
    document.getElementById('perfil-marc').scrollIntoView({ behavior: 'smooth' });
   }
  }


  return (
    <>
    <div className='body-content'>
        <div className='productos' id='nuestros_productos'>
          <div className='grid-prod'>
            <div className='prod1'>
              <img src={carta1} alt=''></img>
            </div>
            <div className='prod2'>
              <img src={carta2} alt=''></img>
            </div>
            <div className='prod3'>
              <img src={carta3} alt=''></img>
            </div>
            <div className='prod4'>
              <img src={carta4} alt=''></img>
            </div>
            <div className='prod5'>
              <img src={carta5} alt=''></img>
            </div>
          </div>
        </div>
  
      <div className='perfil-marca' id='perfil-marc'>
            <div className='grid-brand'>
                <div className='gb1'>
                    <img src={photo1} alt='' className="photo1"></img>   
                    <div className='seccion1'>  
                      <h2 className='nosh2'>
                        LAUBSCHER
                      </h2>
                      <p className='nosp'>
                        Ale americana, orientada a la malta con suave nota a
                         caramelo, presencia equilibrada de lúpulo con aromas...
                      </p>
                      {
                        viewp1===false?
                        <button className='button-info' 
                        onClick={()=>handleviewp1()}
                      >Más Info.
                      </button>:<></>
                      }

                    </div>
                    
                </div>
                <div className='gb2'>
                    <img src={photo2} alt=''></img>
                    <div className='capa-pho2'></div>                    
                    <div className='seccion2'>
                    <h2 className='nosh2'>
                      PILLANS
                      </h2>
                      <p className='nosp'>
                      Lager alemana, oscura, fuerte, de carácter limpio a malta, tostado y ligeras notas a caramelo...
                      </p>
                      {
                        viewp2===false?
                        <button className='button-info' 
                        onClick={()=>handleviewp2()}
                      >Más Info.
                      </button>:<></>
                      }

                    </div>
                </div>
            </div>
    </div>
    </div >
      <div className='descripcion-marca-black' id='des-prod'>
      {
        viewp1===true?
      <div className='marc1-black'>
      <div className='marc1-gri1' id='produc_2'>
        <div className='title-marc1'>
            <p>LAUBSCHER</p>
          </div>
          <div className='text-marc1'>
            <p>
            Ale americana, orientada a la malta con suave nota a
            caramelo, presencia equilibrada de lúpulo con aromas 
            frutales, cítricos y un ligero amargor, lo que la hace resfrescante y fácil de beber.
            </p>
          </div>
          <div className='text-marc2'>
            <h1>Concepto: </h1>
            <p>
              El nombre <b>"Laubscher"</b> es un homenaje a Enríque Laubsher quien en 1883 fundó la Escuela Modelo de Orizaba,
              donde aplicó un nuevo modelo pedagógico con métodos lirícos
              y rítmicos para lograr que los alumnos aprendiera a leer y escribir en un 
              período inusualmente corto.
            </p>
          </div>
          <button className='button-infor' 
             onClick={()=>handleviewp1()}
          >
            Ocultar
          </button>
          <div className='img-conta'>
             <img src={logo} alt='' className='img-logo'></img>
          </div>
         
          
        
      </div>    
     </div>:
    viewp2===true?
    
    <div className='marc2-black'>
    <div className='marc2-gri1' id='produc_2'>
      <div className='title-marc3'>
          <p>PILLANS</p>
        </div>
        <div className='text-marc1'>
          <p>
          Lager alemana, oscura, fuerte, de carácter limpio a malta, tostado y ligeras notas a caramelo, lo que propicia un suave dulzor en el acabado.
          Amargor limpio y definido que resalta la malta con un toque tostado.
          </p>
        </div>
        <div className='text-marc2'>
          <h1>Concepto: </h1>
          <p>
            Los pizarrones del tamaño de pared llegaron en 1800, cuando un director escocés
            llamado James Pillans quiso que sus alumnos dibujaran mapas, según el 
            extracto del libro Slate of Blackboard: A History of the Classroom. Los estudiantes
            no podían dibujar los mapas que la maestra quería en sus pequeños pizarrones, así que Pillans
            unión un grupo de pizarr para crear un solo tablero enorme.
          </p>
        </div>
        <button className='button-infor-2' 
             onClick={()=>handleviewp2()}
          >
            Ocultar
          </button>
    </div> 
       
   </div>:<></>
  }     
 </div>
 </>
  )
}
